<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i
              class="fal fa-calendar"
              style="margin: 0 10px 0 0; font-size: 20px"
            ></i>
            <span @click="main()">{{ $t("taskAllocation.title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'employee_task_allocation.index'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("taskAllocation.assignment_title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'employee_task_allocation.mywork'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("taskAllocation.my_work_title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'employee_task_allocation.assignment'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("taskAllocation.assigned_task_title") }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    main() {
      this.$router
        .push({
          name: "employee_task_allocation.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs-hover {
  font-size: 18px;
}
.content {
  margin-top: 10px;
  padding-top: 30px;
  border-top: 1px solid rgb(95, 95, 95);
}
</style>
